import {LoginDto} from "../core/data/interface/auth/login";
import {ResetPasswordDto} from "../core/data/interface/auth/resetPassword";
import {SendResetCodeDto} from "../core/data/interface/auth/sendResetCode";
import {verifyCodeDto} from "../core/data/interface/auth/VerifyCode";
import {get, post} from "./axios.setup.helper";
import {urlEndpoint} from "./url.helper";

export const backendFunctions = {
    auth: {
        logIn: (data: LoginDto, userAgent: string) =>
            post(urlEndpoint.auth.LOGIN, data, {
                headers: {
                    "User-Agent": userAgent,
                },
            }),
        register: (data = {}) => post(urlEndpoint.users.ALLUSER, data),
        logOut: () => post(urlEndpoint.auth.LOGOUT),
        forgotPassword: {
            sendResetCode: (data: SendResetCodeDto) =>
                post(urlEndpoint.usercode.sendResetCode, data),
            verifyCode: (data: verifyCodeDto) => post(urlEndpoint.usercode.verifyCode, data),
        },
        resetPassword: (data: ResetPasswordDto) => post(urlEndpoint.auth.RESET_PASSWORD, data),
    },
};
