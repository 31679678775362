export const userCompleteCoachesData = [
    {
        coachName: "Kevin Anderson",
        bookingDate: "Booked on : 25 May 2023",
        bookingType: "Onetime",
        date: "Mon, Jul 11",
        time: "06:00 PM - 08:00 PM",
        payment: "$150",
        status: "Terminé",
        details: "Détails",
        img: "assets/img/featured/featured-06.jpg",
    },
    {
        coachName: "Angela Roudrigez",
        bookingDate: "Booked on : 26 May 2023",
        bookingType: "Single Lesson",
        date: "Mon, Jul 12",
        time: "04:00 PM - 06:00 PM",
        payment: "$90",
        status: "Terminé",
        details: "Détails",
        img: "assets/img/featured/featured-02.jpg",
    },
    {
        coachName: "Evon Raddick",
        bookingDate: "Booked on : 27 May 2023",
        bookingType: "Onetime",
        date: "Mon, Jul 15",
        time: "06:00 PM - 08:00 PM",
        payment: "$130",
        status: "Terminé",
        details: "Détails",
        img: "assets/img/featured/featured-05.jpg",
    },
    {
        coachName: "Harry Richardson",
        bookingDate: "Booked on : 28 May 2023",
        bookingType: "Onetime",
        date: "Mon, Jul 16",
        time: "01:00 PM - 02:00 PM",
        payment: "$100",
        status: "Terminé",
        details: "Détails",
        img: "assets/img/featured/featured-03.jpg",
    },
    {
        coachName: "Pete Hill",
        bookingDate: "Booked on : 29 May 2023",
        bookingType: "Onetime",
        date: "Mon, Jul 16",
        time: "05:00 PM - 08:00 PM",
        payment: "$140",
        status: "Terminé",
        details: "Détails",
        img: "assets/img/featured/featured-01.jpg",
    },
];
