/* eslint-disable */
export const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const cleanData = (obj: any): Object => {
    Object.keys(obj).forEach(
        (key) => (obj[key] === undefined || obj[key] === null || obj[key] === "") && delete obj[key]
    );
    return obj;
};
