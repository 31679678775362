export const coachBookingdataOne = [
    {
        courtName: "Wing Sports Academy",
        playerName: "Johnson",
        dateTime: ["Mon, Jul 11", "06:00 PM - 08:00 PM"],
        additionalGuests: 2,
        Montant: "$150",
        Détails: "Détails",
        Chat: "Chat",
        courtImg: "assets/img/booking/booking-01.jpg",
        playerImg: "assets/img/profiles/avatar-01.jpg",
        bookedOn: "26 May 2023",
    },
    {
        courtName: "Feather Badminton",
        playerName: "Andy",
        dateTime: ["Mon, Jul 11", "06:00 PM - 08:00 PM"],
        additionalGuests: 1,
        Montant: "$120",
        Détails: "Détails",
        Chat: "Chat",
        courtImg: "assets/img/booking/booking-02.jpg",
        playerImg: "assets/img/profiles/avatar-02.jpg",
        bookedOn: "26 May 2023",
    },
    {
        courtName: "Bwing Sports Academy",
        playerName: "Pranika",
        dateTime: ["Mon, Jul 11", "06:00 PM - 08:00 PM"],
        additionalGuests: 3,
        Montant: "$130",
        Détails: "Détails",
        Chat: "Chat",
        courtImg: "assets/img/booking/booking-03.jpg",
        playerImg: "assets/img/profiles/avatar-06.jpg",
        bookedOn: "26 May 2023",
    },
    {
        courtName: "Marsh Academy",
        playerName: "Ariyan",
        dateTime: ["Mon, Jul 11", "06:00 PM - 08:00 PM"],
        additionalGuests: 2,
        Montant: "$140",
        Détails: "Détails",
        Chat: "Chat",
        courtImg: "assets/img/booking/booking-04.jpg",
        playerImg: "assets/img/profiles/avatar-03.jpg",
        bookedOn: "26 May 2023",
    },
];
