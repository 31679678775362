import {API_URL} from "./axios.setup.helper";

const AUTH_ENDPOINT = "/auth";
const USER_ENDPOINT = "/clients";
const USERCODE_ENDPOINT = "usercode";
const facebook_url: any = API_URL + AUTH_ENDPOINT.replace("/", "") + "/facebook";
const google_url: any = API_URL + AUTH_ENDPOINT.replace("/", "") + "/google";

export const urlEndpoint = {
    auth: {
        LOGIN: AUTH_ENDPOINT + "/login",
        FACEBOOK: facebook_url,
        GOOGLE: google_url,
        LOGOUT: AUTH_ENDPOINT + "/logout",
        REGISTER: USER_ENDPOINT,
        RESET_PASSWORD: AUTH_ENDPOINT + "/resetPassword",
    },
    users: {
        ALLUSER: USER_ENDPOINT,
        ONE_USER: (id: string) => `${USER_ENDPOINT}/${id}`,
    },
    usercode: {
        sendResetCode: USERCODE_ENDPOINT + "/sendResetCode",
        verifyCode: USERCODE_ENDPOINT + "/verify",
    },
};
