export const all_routes = {
    home: "/home",

    // Blog Routes
    blogGrid: "/blog/blog-grid",
    blogGridSidebarRight: "/blog/blog-grid-sidebar-right",
    blogGridSidebarLeft: "/blog/blog-grid-sidebar-left",
    blogList: "/blog/blog-list",
    blogListSidebarRight: "/blog/blog-list-sidebar-right",
    blogListSidebarLeft: "/blog/blog-list-sidebar-left",
    blogCarousel: "/blog/blog-carousel",
    blogDetails: "/blog/blog-details",
    blogDetailsSidebarRight: "/blog/blog-details-sidebar-right",
    blogDetailsSidebarLeft: "/blog/blog-details-sidebar-left",

    // Pages Routes
    aboutUs: "/pages/about-us",
    expertise: "/pages/about-us#our-expertise",
    mission: "/pages/about-us#our-mission",
    whyIDA: "/pages/about-us#whyIDA",
    team: "/pages/about-us#our-team",
    comingSoon: "/pages/coming-soon",
    error404: "/pages/error-404",
    events: "/pages/events",
    eventdetails: "/pages/event-details",
    serviceDetail: "/pages/service-detail",
    services: "/pages/services",
    termsCondition: "/pages/terms-conditions",
    testimonials: "/pages/testimonials",
    testimonialsCarousel: "/pages/testimonials-carousel",
    maintenance: "/pages/maintenance",
    privacyPolicy: "/pages/privacy-policy",
    cookiesPolicy: "/pages/cookies-policy",
    pricing: "/pages/pricing",
    ourTeams: "/pages/our-teams",
    myProfile: "/pages/my-profile",
    faq: "/pages/frequently-asked-questions",
    gallery: "/pages/gallery",
    invoice: "/coaches/invoice",
    lessonOrderConfirm: "/coaches/lesson-order-confirm",
    lessonPayment: "coaches/lesson-payment",
    lessonPersonalinfo: "coaches/lesson-personalinfo",
    userWallet: "/user/user-wallet",

    // Coaches Routes
    bookingCancelled: "/coaches/booking-cancelled",
    bookingCompleted: "/coaches/booking-completed",
    allCourt: "/coaches/all-court",
    appointmentDetails: "/coaches/appointment-details",
    addCourt: "/coaches/add-court",
    activeCourt: "/coaches/active-court",
    inactiveCourt: "/coaches/inactive-court",
    coachDetail: "/coaches/coach-detail",
    coachDetails: "/coaches/coach-details",
    coachEarning: "/coaches/coach-earning",
    coachesGrid: "/coaches/coaches-grid",
    coachesGridSidebar: "/coaches/coaches-sidebar-grid",
    coachesList: "/coaches/coaches-list",
    coachesListSidebar: "/coaches/coaches-sidebar-list",
    coachesMap: "/coaches/coaches-map",
    coachRequest: "/coaches/coach-request",
    coachWallet: "/coaches/coach-wallet",
    coachTimeDate: "/coaches/coach-timedate",
    coachProfile: "/coaches/coach-profile",
    coachPersonalInfo: "/coaches/coach-personalinfo",
    coachPayment: "/coaches/coach-payment",
    coachOrederConfirm: "/coaches/coach-order-confirm",
    coachesMapSidebar: "/coaches/coaches-map-sidebar",
    earningCoaching: "/coaches/earning-coaching",
    cagecheckout: "/coaches/cage-checkout",
    cagedetails: "/coaches/cage-details",
    cageordeconfirm: "/coaches/cage-order-confirm",
    cagepersonalinfo: "/coaches/cage-personalinfo",
    lessonTimeDate: "/coaches/lesson-timedate",
    lessonType: "/coaches/lesson-type",
    settingLesson: "/coaches/setting-lesson",
    settingPassword: "/coaches/setting-password",
    settingAvalibilty: "/coaches/setting-availability",
    profileOtherSetting: "/coaches/profile-othersetting",
    coachDashboard: "coaches/coach-dashboard",
    coachBooking: "/coaches/coach-booking",
    coachChat: "/coaches/coach-chat",
    venueDetails: "/coaches/venue-details",

    // User Routes
    userComplete: "/user/user-complete",
    userDashboardProfiles: "/user/user-dashboard-profiles",
    userDashboard: "/user/user-dashboard",
    userInvoice: "/user/user-invoice",
    userBookings: "/user/user-bookings",
    userChat: "/user/user-chat",
    userCoaches: "/user/user-coaches",
    userProfile: "/user/user-profile",
    userSettingPassword: "/user/user-setting-password",
    userProfileOtherSetting: "/user/user-profile-othersetting",
    userCancelled: "/user/user-cancelled",
    userOngoing: "/user/user-ongoing",
    userClaim: "/user/user-claim",

    // Listing Routes
    listingGridSidebar: "/listing/listing-grid-sidebar",
    listingGrid: "/listing/listing-grid",
    listingListSidebar: "/listing/listing-list-sidebar",
    listingList: "/listing/listing-list",
    listingMapSidebar: "/listing/listing-map-sidebar",
    listingMap: "/listing/listing-map",

    // Auth Routes
    register: "/auth/register",
    login: "/auth/login",
    changePassword: "/auth/change-password",
    forgotPasssword: "/auth/forgot-password",

    //contact-us
    contactUs: "/contact-us",

    //Frequently Asked Questions
};
