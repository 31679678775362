export const userOngoingdata = [
    {
        image: "assets/img/booking/booking-03.jpg",
        image2: "assets/img/featured/featured-06.jpg",
        name: "Kevin Anderson",
        category: "Onetime",
        booked: "26 May 2023",
        content: "BWing Sports Academy",
        court: "Court 1",
        date: "Tue, Jul 12",
        time: "05:00 PM - 06:00 PM",
        payment: "$400",
        paiddate: "Jul 12, 2023",
        bookingtype: "Single Lesson",
        status: "En cours",
        coachName: "Angela Roudrigez",
        bookeddata: "Booked on : 26 May 2023",
        star: true,
    },
    {
        image: "assets/img/booking/booking-02.jpg",
        image2: "assets/img/featured/featured-05.jpg",
        booked: "25 Dec 2023",
        name: "Harry Richardson",
        category: "Ontime",
        content: "Feather Badminton",
        court: "Court 2",
        date: "Wed, Jul 02",
        time: "05:00 AM - 06:00 PM",
        payment: "$200",
        paiddate: "Jan 27, 2023",
        bookingtype: "Onetime",
        status: "En cours",
        coachName: "Evon Raddick",
        bookeddata: "Booked on : 26 May 2023",
        star: false,
    },
    {
        image: "assets/img/booking/booking-01.jpg",
        image2: "assets/img/featured/featured-02.jpg",
        name: "Angela Roudrigez",
        booked: "17 Apr 2023",
        category: "Single Lesson",
        content: "Leap Sports Academy",
        court: "Court 3",
        date: "Wed, Jun 07",
        time: "04:00 AM - 11:00 PM",
        payment: "$130",
        paiddate: "Apr 12, 2023",
        bookingtype: "Onetime",
        status: "En cours",
        coachName: "Harry Richardson",
        bookeddata: "Booked on : 28 May 2023",
        star: false,
    },
    {
        image: "assets/img/booking/booking-04.jpg",
        image2: "assets/img/featured/featured-09.jpg",
        booked: "26 May 2023",
        name: "Pete Hill",
        category: "Ontime",
        content: "Marsh Academy",
        court: "Court 4",
        date: "Wed, Jul 02",
        time: "08:00 AM - 06:00 PM",
        payment: "$90",
        paiddate: "Jan 28, 2023",
        bookingtype: "Onetime",
        status: "En cours",
        coachName: "Kevin Anderson",
        bookeddata: "Booked on : 25 May 2023",
        star: true,
    },
    {
        image: "assets/img/booking/booking-05.jpg",
        image2: "assets/img/featured/featured-04.jpg",
        booked: "20 Jun 2023",
        name: "Evon Raddick",
        category: "Onetime",
        content: "Wing Sports Academy",
        court: "Court 5",
        date: "Sun, Dec 17",
        time: "11:00 AM - 06:00 PM",
        payment: "$150",
        paiddate: "Nov 09, 2023",
        bookingtype: "Onetime",
        status: "En cours",
        bookeddata: "Booked on : 29 May 2023",
        coachName: "Pete Hill",
        star: true,
    },
];
