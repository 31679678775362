export const inactiveCourt = [
    {
        courtName: "Leap Sports Academy",
        img: "assets/img/booking/booking-01.jpg",
        courtNo: "Court 1",
        location: "Victoria, TX",
        amount: "$120",
        maxGuest: "1",
        additionalGuests: "2",
        addedOn: "Mon, Jul 12",
        details: "View details",
        status: "checkbox",
    },
    {
        img: "assets/img/booking/booking-02.jpg",
        courtName: "Wing Sports Academy",
        courtNo: "Court 2",
        location: "South Bend, IN",
        amount: "$180",
        maxGuest: "1",
        additionalGuests: "2",
        addedOn: "Wed, Jul 12",
        details: "View details",
        status: "checkbox",
    },
];
