export const coachWallet = [
    {
        refID: "#CO14",
        transactionFor: "Kevin Anderson",
        date: "Mon, Jul 11",
        time: "04:00 PM - 06:00 PM",
        payment: "$150",
        status: "Paid",
        img: "assets/img/profiles/avatar-01.jpg",
    },
    {
        refID: "#CO15",
        transactionFor: "Angela Roudrigez",
        date: "Mon, Jul 11",
        time: "01:00 PM - 04:00 PM",
        payment: "$200",
        status: "Pending",
        img: "assets/img/profiles/avatar-06.jpg",
    },
    {
        refID: "#CO16",
        transactionFor: "Wing Sports Academy",
        date: "Mon, Jul 11",
        time: "05:00 PM - 08:00 PM",
        payment: "$150",
        status: "Failed",
        img: "assets/img/profiles/avatar-03.jpg",
    },
    {
        refID: "#CO17",
        transactionFor: "Feather Badminton",
        date: "Mon, Jul 11",
        time: "01:00 PM - 04:00 PM",
        payment: "$90",
        status: "Paid",
        img: "assets/img/profiles/avatar-04.jpg",
    },
    {
        refID: "#CO18",
        transactionFor: "Pete Hill",
        date: "Mon, Jul 11",
        time: "03:00 PM - 08:00 PM",
        payment: "$180",
        status: "Paid",
        img: "assets/img/profiles/avatar-06.jpg",
    },
];
