export const userInvoiceData = [
    {
        id: "#CO14",
        courtName: "Leap Sports Academy",
        courtNo: "Court 1",
        date: "Mon, Jul 12",
        time: "06:00 PM - 08:00 PM",
        payment: "$150",
        paidOn: "Mon, Jul 12",
        status: "Paid",
        download: "Télécharger",
        img: "assets/img/booking/booking-01.jpg",
    },
    {
        id: "#CO15",
        courtName: "Wing Sports Academy",
        courtNo: "Court 1",
        date: "Mon, Jul 13",
        time: "07:00 PM - 09:00 PM",
        payment: "$200",
        paidOn: "Mon, Jul 13",
        status: "Pending",
        download: "Télécharger",
        img: "assets/img/booking/booking-02.jpg",
    },
    {
        id: "#CO16",
        courtName: "Feather Badminton",
        courtNo: "Court 1",
        date: "Mon, Jul 14",
        time: "01:00 PM - 02:00 PM",
        payment: "$150",
        paidOn: "Mon, Jul 14",
        status: "Failed",
        download: "Télécharger",
        img: "assets/img/booking/booking-03.jpg",
    },
    {
        id: "#CO17",
        courtName: "Bwing Sports Academy",
        courtNo: "Court 1",
        date: "Mon, Jul 15",
        time: "01:00 PM - 04:00 PM",
        payment: "$90",
        paidOn: "Mon, Jul 15",
        status: "Paid",
        download: "Télécharger",
        img: "assets/img/booking/booking-04.jpg",
    },
    {
        id: "#CO18",
        courtName: "Bwing Sports Academy",
        courtNo: "Court 1",
        date: "Mon, Jul 16",
        time: "03:00 PM - 05:00 PM",
        payment: "$180",
        paidOn: "Mon, Jul 16",
        status: "Paid",
        download: "Télécharger",
        img: "assets/img/booking/booking-05.jpg",
    },
];
