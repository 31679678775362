export const CreateClientInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    pwd: "",
    picture: null,
    externalAuthProvider: null,
    passwordResetDate: null,
    createdAt: "",
    updatedAt: "",
};
