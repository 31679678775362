export const userCompleteCourtsData = [
    {
        courtName: "Leap Sports Academy",
        courtNo: "Court 1",
        date: "Mon, Jul 11",
        time: "06:00 PM - 08:00 PM",
        payment: "$150",
        details: "Détails",
        status: "Terminé",
        img: "assets/img/booking/booking-03.jpg",
    },
    {
        courtName: "Feather Badminton",
        courtNo: "Court 1",
        date: "Mon, Jul 12",
        time: "01:00 PM - 04:00 PM",
        payment: "$90",
        details: "Détails",
        status: "Terminé",
        img: "assets/img/booking/booking-02.jpg",
    },
    {
        courtName: "Bwing Sports Academy",
        courtNo: "Court 1",
        date: "Mon, Jul 15",
        time: "02:00 PM - 04:00 PM",
        payment: "$130",
        details: "Détails",
        status: "Terminé",
        img: "assets/img/booking/booking-05.jpg",
    },
    {
        courtName: "Marsh Academy",
        courtNo: "Court 1",
        date: "Mon, Jul 16",
        time: "03:00 PM - 05:00 PM",
        payment: "$100",
        details: "Détails",
        status: "Terminé",
        img: "assets/img/booking/booking-01.jpg",
    },
    {
        courtName: "Wing Sports Academy",
        courtNo: "Court 1",
        date: "Mon, Jul 16",
        time: "06:00 PM - 08:00 PM",
        payment: "$140",
        details: "Détails",
        status: "Terminé",
        img: "assets/img/booking/booking-06.jpg",
    },
];
