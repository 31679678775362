import React from "react";
import {Link} from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import {all_routes} from "../router/all_routes";

const Pricing = () => {
    const routes = all_routes;
    return (
        <div>
            <>
                {/* Breadcrumb */}
                <div className="breadcrumb breadcrumb-list mb-0">
                    <span className="primary-right-round" />
                    <div className="container">
                        <h1 className="text-white">Achat de Packs</h1>
                        <ul>
                            <li>
                                <Link to={routes.home}>Accueil</Link>
                            </li>
                            <li>Packs</li>
                        </ul>
                    </div>
                </div>
                {/* /Breadcrumb */}
                {/* Page Content */}
                <div className="content">
                    {/* Featured Plans */}
                    <section className="section featured-plan">
                        <div className="work-img ">
                            <div className="work-img-right">
                                <ImageWithBasePath
                                    src="assets/img/bg/soccer-player-alone-wbg.png"
                                    alt="Icon"
                                />
                            </div>
                        </div>
                        <div className="container">
                            <div className="section-heading aos" data-aos="fade-up">
                                <h2>
                                    Nous avons de <span>super Packs pour vous</span>
                                </h2>
                                <p className="sub-title">
                                    Nous proposons des services sur mesure en fonction de vos
                                    besoins spécifiques.
                                </p>
                            </div>
                            <div className="interset-btn aos" data-aos="fade-up">
                                <div className="status-toggle d-inline-flex align-items-center">
                                    National
                                    <input type="checkbox" id="status_1" className="check" />
                                    <label htmlFor="status_1" className="checktoggle">
                                        checkbox
                                    </label>
                                    International
                                </div>
                            </div>
                            <div className="interset-btn aos" data-aos="fade-up">
                                <div className="status-toggle d-inline-flex align-items-center">
                                    Mensuel
                                    <input type="checkbox" id="status_1" className="check" />
                                    <label htmlFor="status_1" className="checktoggle">
                                        checkbox
                                    </label>
                                    Annuel
                                </div>
                            </div>

                            <div className="price-wrap aos" data-aos="fade-up">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 d-flex col-md-6">
                                        {/* Price Card */}
                                        <div className="price-card flex-fill ">
                                            <div className="price-head">
                                                <ImageWithBasePath
                                                    src="assets/img/icons/price-01.svg"
                                                    alt="Price"
                                                />
                                                <h3>Pack Argent</h3>
                                            </div>
                                            <div className="price-body">
                                                <div className="per-month">
                                                    <h2>
                                                        <span>65 000</span>
                                                        <sup>FCFA</sup>
                                                    </h2>
                                                    <span>Par Mois HT</span>
                                                </div>
                                                <div className="features-price-list">
                                                    <h5>Options</h5>
                                                    <p>Vidéos</p>
                                                    <ul>
                                                        <li className="active">
                                                            <i className="feather-check-circle" />
                                                            Inclus : Ligue 1
                                                        </li>
                                                        <li className="active">
                                                            <i className="feather-check-circle" />
                                                            Inclus : Championnat de U20
                                                        </li>
                                                        <li className="inactive">
                                                            <i className="feather-x-circle" />
                                                            Analyse Data (Données){" "}
                                                        </li>
                                                        {/* <li className="inactive">
                                                    <i className="feather-x-circle" />-
                                                </li> */}
                                                    </ul>
                                                </div>
                                                <div className="price-choose">
                                                    <Link to="#" className="btn viewdetails-btn">
                                                        Choisissez votre pack
                                                    </Link>
                                                </div>
                                                {/* <div className="price-footer">
                                            <p>
                                                Use, by you or one client, in a single end product
                                                which end users. charged for. The total price
                                                includes the item price and a buyer fee.
                                            </p>
                                        </div> */}
                                            </div>
                                        </div>
                                        {/* /Price Card */}
                                    </div>
                                    <div className="col-lg-4 d-flex col-md-6">
                                        {/* Price Card */}
                                        <div className="price-card flex-fill">
                                            <div className="price-head expert-price">
                                                <ImageWithBasePath
                                                    src="assets/img/icons/price-02.svg"
                                                    alt="Price"
                                                />
                                                <h3>Pack Or</h3>
                                                <span>Recommandé</span>
                                            </div>
                                            <div className="price-body">
                                                <div className="per-month">
                                                    <h2>
                                                        <span>120 000</span>
                                                        <sup>FCFA</sup>
                                                    </h2>
                                                    <span>Par Mois HT</span>
                                                </div>
                                                <div className="features-price-list">
                                                    <h5>Options</h5>
                                                    <p>Vidéos</p>
                                                    <ul>
                                                        <li className="active">
                                                            <i className="feather-check-circle" />
                                                            Inclus : Ligue 1
                                                        </li>
                                                        <li className="active">
                                                            <i className="feather-check-circle" />
                                                            Inclus : Championnat de U20
                                                        </li>
                                                        <li className="active">
                                                            <i className="feather-check-circle" />
                                                            Analyse Data (Données){" "}
                                                        </li>
                                                        {/* <li className="inactive">
                                                    <i className="feather-x-circle" />-
                                                </li> */}
                                                    </ul>
                                                </div>
                                                <div className="price-choose active-price">
                                                    <Link to="#" className="btn viewdetails-btn">
                                                        Choisissez votre pack
                                                    </Link>
                                                </div>
                                                {/* <div className="price-footer">
                                            <p>
                                                Use, by you or one client, in a single end product
                                                which end users. charged for. The total price
                                                includes the item price and a buyer fee.
                                            </p>
                                        </div> */}
                                            </div>
                                        </div>
                                        {/* /Price Card */}
                                    </div>
                                    {/* TODO : Tarif internationaux */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Featured Plans */}
                </div>
                {/* /Page Content */}
            </>
        </div>
    );
};

export default Pricing;
